import React from "react";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import SEO from "../components/SEO";
export default function PrivacyPolicy() {
  return (
    <>
      <SEO title={"Privacy Policy"}/>
      <Navbar />
      <div className="siteBackground">
        <div className="wrapper">
          <div className="greyPageCard articleText">
            <h1>Privacy Policy</h1>
            <br></br>
            <h4>Consent</h4>
            <p>
              By using our website, you hereby consent to our Privacy Policy and
              agree to its terms.
            </p>
            <h4>Information we collect</h4>
            <p>
              The personal information that you are asked to provide, and the
              reasons why you are asked to provide it, will be made clear to you
              at the point we ask you to provide your personal information. If
              you contact us directly, we may receive additional information
              about you such as your name, email address, phone number, the
              contents of the message and/or attachments you may send us, and
              any other information you may choose to provide.
            </p>
            <h4>How we use your information</h4>
            <p>
              We use the information we collect in various ways, including to:
            </p>
            <ul>
              <li>• Provide, operate, and maintain our website</li>
              <li>• Improve, personalize, and expand our website</li>
              <li>• Understand and analyze how you use our website</li>
              <li>
                • Communicate with you, either directly or through one of our
                partners, including for customer service, to provide you with
                updates and other information relating to the website, and for
                marketing and promotional purposes
              </li>
              <li>• Find and prevent fraud</li>
            </ul>
            <h4>Cookies and Web Beacons</h4>
            <p>
              Like any other website, Pluggdriven uses 'cookies'. These
              cookies are used to store information including visitors'
              preferences, and the pages on the website that the visitor
              accessed or visited. The information is used to optimize the
              users' experience by customizing our web page content based on
              visitors' browser type and/or other information.
            </p>
            <h4>Our Advertising Partners</h4>
            <p>
              Some of advertisers on our site may use cookies and web beacons.
              Our advertising partners are listed below. Each of our advertising
              partners has their own Privacy Policy for their policies on user
              data.
            </p>
            <h4>Advertising Partners Privacy Policies</h4>
            <p>
              You may consult this list to find the Privacy Policy for each of
              the advertising partners of Pluggdriven. Third-party ad
              servers or ad networks uses technologies like cookies, JavaScript,
              or Web Beacons that are used in their respective advertisements
              and links that appear on Pluggdriven, which are sent directly
              to users' browser. They automatically receive your IP address when
              this occurs. These technologies are used to measure the
              effectiveness of their advertising campaigns and/or to personalize
              the advertising content that you see on websites that you visit.
              Note that Pluggdriven has no access to or control over these
              cookies that are used by third-party advertisers.
            </p>
            <h4>Third Party Privacy Policies</h4>
            <p>
              Pluggdriven's Privacy Policy does not apply to other
              advertisers or websites. Thus, we are advising you to consult the
              respective Privacy Policies of these third-party ad servers for
              more detailed information. It may include their practices and
              instructions about how to opt-out of certain options. You can
              choose to disable cookies through your individual browser options.
              To know more detailed information about cookie management with
              specific web browsers, it can be found at the browsers' respective
              websites.
            </p>
            <h4>CCPA Privacy Rights (Do Not Sell My Personal Information)</h4>
            <p>
              Under the CCPA, among other rights, California consumers have the
              right to: Request that a business that collects a consumer's
              personal data disclose the categories and specific pieces of
              personal data that a business has collected about consumers.
              Request that a business delete any personal data about the
              consumer that a business has collected. Request that a business
              that sells a consumer's personal data, not sell the consumer's
              personal data. If you make a request, we have one month to respond
              to you. If you would like to exercise any of these rights, please
              contact us.
            </p>
            <h4>GDPR Data Protection Rights</h4>
            <p>
              We would like to make sure you are fully aware of all of your data
              protection rights. Every user is entitled to the following: The
              right to access – You have the right to request copies of your
              personal data. We may charge you a small fee for this service. The
              right to rectification – You have the right to request that we
              correct any information you believe is inaccurate. You also have
              the right to request that we complete the information you believe
              is incomplete. The right to erasure – You have the right to
              request that we erase your personal data, under certain
              conditions. The right to restrict processing – You have the right
              to request that we restrict the processing of your personal data,
              under certain conditions. The right to object to processing – You
              have the right to object to our processing of your personal data,
              under certain conditions. The right to data portability – You have
              the right to request that we transfer the data that we have
              collected to another organization, or directly to you, under
              certain conditions. If you make a request, we have one month to
              respond to you. If you would like to exercise any of these rights,
              please contact us.
            </p>
            <br/>
          </div>
        </div>
      </div>
      <Footer dark={true} />
    </>
  );
}
